@import "css/src/colours";
@import "css/src/variables";

.drawerInput {
  margin-left: $minor-padding;
}

.marginTop {
  margin-top: 20px;
}
.when {
  display: inline-block;
  color: $grey;
  margin-top: 0.25rem;
}
