@import "mixins";

$gutter: 0.75rem;

// Small screens
.row {
  @include display-flex;
  flex-wrap: wrap;
  margin-left: -$gutter;
  margin-right: -$gutter;
}

.row-margin {
  margin-bottom: 1rem;
}

.row > .col {
  @include flex(1);
  padding-right: $gutter;
  padding-left: $gutter;

  &.s1 {
    @include flex(8.33333%);
  }
  &.s2 {
    @include flex(16.6667%);
  }
  &.s3 {
    @include flex(25%);
  }
  &.s4 {
    @include flex(33.33333%);
  }
  &.s5 {
    @include flex(41.66667%);
  }
  &.s6 {
    @include flex(50%);
  }
  &.s7 {
    @include flex(58.33333%);
  }
  &.s8 {
    @include flex(66.66667%);
  }
  &.s9 {
    @include flex(75%);
  }
  &.s10 {
    @include flex(83.33333%);
  }
  &.s11 {
    @include flex(91.66667%);
  }
  &.s12 {
    @include flex(100%);
  }
}

// Small to medium screens
@media (min-width: 1024px) {
  .row > .col {
    &.m1 {
      @include flex(8.33333%);
    }
    &.m2 {
      @include flex(16.66667%);
    }
    &.m3 {
      @include flex(25%);
    }
    &.m4 {
      @include flex(33.33333%);
    }
    &.m5 {
      @include flex(41.66667%);
    }
    &.m6 {
      @include flex(50%);
    }
    &.m7 {
      @include flex(58.33333%);
    }
    &.m8 {
      @include flex(66.66667%);
    }
    &.m9 {
      @include flex(75%);
    }
    &.m10 {
      @include flex(83.33333%);
    }
    &.m11 {
      @include flex(91.66667%);
    }
    &.m12 {
      @include flex(100%);
    }
  }
}

// Large screens
@media (min-width: 1224px) {
  .row > .col {
    &.l1 {
      @include flex(8.33333%);
    }
    &.l2 {
      @include flex(16.6667%);
    }
    &.l3 {
      @include flex(25%);
    }
    &.l4 {
      @include flex(33.33333%);
    }
    &.l5 {
      @include flex(41.66667%);
    }
    &.l6 {
      @include flex(50%);
    }
    &.l7 {
      @include flex(58.33333%);
    }
    &.l8 {
      @include flex(66.66667%);
    }
    &.l9 {
      @include flex(75%);
    }
    &.l10 {
      @include flex(83.33333%);
    }
    &.l11 {
      @include flex(91.66667%);
    }
    &.l12 {
      @include flex(100%);
    }
  }
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
.container-margin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.container-fluid > .row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}

.flex-content-fluid {
  margin: 2rem 0;
  padding: 0 1rem;

  @media (min-width: 720px) {
    margin: 3rem 0;
    padding: 0 3rem;
  }
}
