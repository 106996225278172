@import "css/src/variables";
@import "css/src/colours";

.modal {
  .modalHeader {
    &.concise {
      max-width: 24rem;
    }
  }

  .modalContent {
    flex: 1;
  }

  .title {
    margin: 0;
  }
}
