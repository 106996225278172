@import "css/src/variables";
@import "css/src/colours";
@import "css/src/mixins";

$container-width: 1080px;
$spacing-xl: 100px;
$spacing-large: 50px;
$tablet-width-mobile-ratio: 20rem;
$tablet-width-tablet-ratio: 50rem;

@mixin marketing-max-width {
  max-width: $container-width;
  margin: 0 auto;
}

.verticalAlign {
  @include vertical-flex-align;
}

.horizontalAlign {
  @include horizontal-flex-align;
}

.centerAlign {
  @include display-flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.relative {
  position: relative;
}

.halfHalf {
  @include display-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $spacing-large;

  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
    margin-bottom: $spacing-xl;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.largerRight,
  &.largerLeft {
    flex-direction: column-reverse;
    grid-column-gap: 4rem;

    @include tablet {
      &.largerRight {
        grid-template-columns: 1fr 50%;
      }
      &.largerLeft {
        grid-template-columns: 55% 1fr;
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

.marketingButton {
  text-transform: none;

  &.buttonHero {
    padding: 0 1.5rem;
    line-height: 4rem;
    font-weight: bold;
  }
}

.heroButtons {
  .buttonHero,
  & > a {
    width: 100%;

    @include tablet {
      width: auto;
    }
  }

  & > a {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }

    @include tablet {
      margin-bottom: 0;
    }
  }
}

.heroHalfHalf {
  flex-direction: column-reverse;
}

.heroSection {
  flex-direction: row;
  padding-top: 0 !important;

  .heroSectionContent {
    margin: 0 auto;
  }
}

.heroProductDemo {
  justify-content: center;
}

.page {
  background-color: $page-secondary-background;
  overflow: hidden;
}

.navBarContainer {
  position: relative;
}

.navBar {
  @include marketing-max-width;
  padding: $minor-padding $spacing-large;
  text-align: center;

  @include tablet {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-large $spacing-xl;
  }

  .navBarLogoContainer {
    text-align: center;
  }

  .navBarLogo {
    max-width: 10rem;
  }
}

.navBarMenu {
  display: none;
  align-items: center;
  margin: 0;

  @include tablet {
    @include display-flex;
  }

  .menuLink {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.sectionWrapper {
  padding: $spacing-xl;
  margin-left: -$spacing-xl;
  margin-right: -$spacing-xl;
  background: rgb(244, 245, 247);
  border-radius: $spacing-xl;
}

.section {
  position: relative;
  @include marketing-max-width;
  @include display-flex;
  flex-direction: column;
  min-height: calc(100vh - 12em);
  align-items: center;
  justify-content: center;
  padding: $spacing-large;

  @include tablet {
    padding: $spacing-xl;
  }
}

.paragraphInfo {
  font-size: 1.25rem !important;

  &.paragraphInfoLarge {
    font-size: 1.5rem !important;
  }

  &.paragraphConcise {
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
  }

  &.paragraphSpace {
    margin-bottom: $spacing-large;

    @include tablet {
      margin-bottom: $spacing-xl;
    }
  }
}

.paragraphHeader {
  font-size: 1.75rem !important;
  font-weight: bold;
}

.marketingTitle {
  font-size: 3rem;
  max-width: 45rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.marketingHeader {
  font-size: 2.5rem;
  max-width: 45rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.testimonials,
.pricing,
.workspaceAvailable {
  @include display-flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
}

.pricing {
  margin-bottom: $spacing-xl;
}

.inlineStatusLogo {
  @include display-flex;
  align-items: center;
  margin-top: $major-padding;

  & > span {
    color: $purple-dark;
  }

  .inlineStatusLogoImage {
    display: inline-block;
    width: 10rem;
    height: auto;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }
}

.footerLogo {
  max-width: 10rem;
  margin-bottom: 20px;
  display: block;
}

.footerSection {
  max-width: none;
  min-height: 0;
  background-color: $purple-dark;
  padding-bottom: 50px;
}

.footer {
  @include marketing-max-width;
  width: 100%;
  color: white;

  .linkGroups {
    @include display-flex;
    justify-content: flex-start;
    margin-bottom: $spacing-large;

    @include tablet {
      margin-bottom: 0;
      justify-content: flex-end;
    }

    .linkGroup {
      &:last-child {
        margin-right: 0;
      }

      h4 {
        color: white;
      }

      ul {
        list-style: none;
        padding: 0;

        a {
          color: white;
        }

        li {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .copyright {
    display: block;
    margin-top: $spacing-large;
  }
}

.productImage {
  display: block;
  width: 100%;
  max-width: 50rem;
  height: auto;

  &.productImageSmall {
    max-width: 300px;
    margin-left: 0;
  }

  &.productImageTiny {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.productDemoPhone {
  position: absolute;
  top: -2rem;
}

.phoneDemo {
  @include no-select;
  width: 14rem;
  height: 28rem;
  padding: 8px;
  border-radius: 36px;
  background: #f6f9fc;
  box-shadow:
    0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);

  & .screen {
    @include display-flex;
    height: 100%;
    align-items: center;
    overflow: hidden;
    border-radius: 34px;

    img {
      display: inline-block;
      width: 100%;
      margin-left: 0;
    }
  }
}

.tabletDemo {
  @include no-select;
  padding: 7px;
  border-radius: 30px;
  background: #f6f9fc;
  box-shadow:
    0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);

  @include tablet {
    padding: 13px;
    border-radius: 36px;
  }

  & .screen {
    @include display-flex;
    align-items: center;
    overflow: hidden;
    border-radius: 23px;

    @include tablet {
      border-radius: 26px;
    }

    img {
      display: inline-block;
      max-width: 60rem;
      width: 100%;
      margin-left: 0;
    }
  }
}

.softwareDemo {
  margin-top: $spacing-l;
  margin-bottom: 2 * $spacing-xl;
}

.whereToBuy {
  @include display-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .partner {
    height: 12rem;
    width: 12rem;
    font-size: 20px;
    box-shadow:
      0 20px 70px -30px rgba(50, 50, 93, 0.12),
      0 30px 40px -30px rgba(0, 0, 0, 0.3),
      inset 0 -2px 6px 0 rgba(10, 37, 64, 0.25);

    .partnerImage {
      background-size: 85%;
    }
  }

  a {
    display: block;
    margin-top: $spacing-large;
  }

  @include tablet {
    flex-direction: row;
    justify-content: space-around;
  }
}

$photon-size: 0.75rem;

.animatedNetwork {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 950px;

  .photonTrail {
    position: absolute;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    background-color: rgba(133, 77, 255, 0.2);
  }

  .photon {
    position: absolute;
    left: 50%;
    margin-top: -1px;
    margin-left: -$photon-size/2;
    background: $color-primary-light;
    border-radius: 50%;
    width: $photon-size;
    height: $photon-size;
    box-shadow: 0 0 1rem 4px rgba(133, 77, 255, 0.6);
  }
}

.workspacePill {
  margin-bottom: $spacing-l;
}
