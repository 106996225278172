@import "css/src/variables";
@import "css/src/mixins";

.stageBase {
  @include display-flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem 10rem;

  @media only screen and (min-width: 768px) {
    padding: $major-padding $major-padding 10rem;
  }
}

.comfortable {
  max-width: 960px;
}

.sheet {

}

.fullWidth {
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  padding-bottom: 0;
  margin-bottom: 5rem;
}
