// Import font
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

// Components
@import "react-select";
@import "css/src/reset";
@import "css/src/atlaskit";
@import "css/src/grid";
@import "css/src/typography";
@import "css/src/icons";
@import "css/src/cards";
@import "css/src/pagination";
@import "css/src/lists";
@import "css/src/block-grid";
@import "css/src/modal";
@import "css/src/animations";
@import "css/src/captions";
@import "css/src/components";

@layer base {
  html {
    font-family: $default-font-family;
  }
}

// Apps
@import "css/src/apps/apps";

// Views
@import "css/src/views/readers";
@import "css/src/views/users";
@import "css/src/views/locations";
@import "css/src/views/tools";
@import "css/src/views/toolboxes";
@import "css/src/views/dashboard";
@import "css/src/views/toolbox-dashboard";
