@import "css/src/colours";
@import "css/src/variables";
@import "css/src/mixins";

$icon-background-size: 2.8rem;
$icon-size: 1.875rem;
$icon-background-border-radius: 4px;

.item {
  display: flex;
  flex-direction: row;
  padding: $minor-padding/2 $minor-padding;
  cursor: pointer;
  @include transition(background-color, 0.3s);
  border-radius: $border-radius;

  &:hover {
    background-color: rgb(244, 245, 247);
  }
}

.iconWrapper {
  background: lighten($color-primary, 32%);
  width: $icon-background-size;
  height: $icon-background-size;
  border-radius: $icon-background-border-radius;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .icon {
    fill: $color-primary;
    width: $icon-size;
    height: $icon-size;
    margin: 0 auto;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  display: inline-block;
  margin-bottom: 4px;
}

.description {
  color: #a6a6a6;
}
