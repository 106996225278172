@import "css/src/variables";
@import "css/src/colours";

.modal {
  text-align: center;
  min-width: 1px;
  max-width: 30rem;
  width: 100%;
}

.description {
  padding: 0 1.5rem;
  margin-bottom: 0 !important;
}
