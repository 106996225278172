$spacing: 20px;

@mixin line {
  content: "";
  display: block;
  border-left: 1px solid #dadce0;
  width: 1px;
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  bottom: 0;
}

.event {
  display: flex;
  flex-direction: row;

  .eventContent {
    margin-left: $spacing;

    .name {
      display: block;
      line-height: 1.5;
    }
    .description {
      color: #a6a6a6;
    }
  }

  &:not(:last-child) {
    .icon {
      height: 100%;
      &:after {
        @include line;
      }
    }
    .eventContent {
      margin-bottom: $spacing;
    }
  }
}
