@import "css/src/variables";
@import "css/src/colours";
@import "css/src/mixins";

.viewers {
  @include display-flex;
  margin: 0 1.25rem;

  .viewerAvatar {
    margin-right: -0.25rem;
    border-radius: 50%;
    border: 1px solid currentColor;
  }
}

// Outside as they are added to a portal element
.viewerEmail,
.viewerTime {
  display: block;
  font-size: 0.8em;
  line-height: 1.2rem;
}
.viewerTime {
  opacity: 0.7;
}
