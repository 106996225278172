@import "css/src/colours";
@import "css/src/variables";
@import "css/src/mixins";

$dropdown-overlay-zIndex: 2;
$dropdown-item-hover-background-color: #f6f6f6;

.dropButtonContainer {
  position: relative;

  .dropButton {
    white-space: nowrap;
  }

  .dropButtonVisible {
    z-index: $dropdown-overlay-zIndex + 1; // So that the button is over the overlay
  }

  .dropButtonBorder {
    border: 1px solid $input-border-color;
  }
}

.dropdown {
  position: absolute;
  background-color: $card-background-color;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: $card-border-radius;
  padding: 0.5rem;
  z-index: $dropdown-overlay-zIndex + 1;
  margin-top: 0.5rem;
  overflow: hidden;

  &.small {
    min-width: 0;
  }
  &.medium {
    min-width: 14rem;
  }
  &.large {
    min-width: 22rem;
  }

  &.dropdownAlignRight {
    right: 0.7rem;
  }
}

.dropdownOverlay {
  z-index: $dropdown-overlay-zIndex;
}

.dropItem {
  @include display-flex;
  width: 100%;
  padding: 1rem;
  border-radius: $card-border-radius - 1px;
  color: $font-color;

  .dropItemContent {
    width: 100%;
  }

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    background-color: $dropdown-item-hover-background-color;
    color: $color-primary;
  }

  .dropItemIconContainer {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    .dropItemIcon {
    }
  }

  &.normal {
  }

  &.input {
    padding: 0.5rem;
  }
}
