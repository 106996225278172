@import "css/src/variables";
@import "css/src/mixins";
@import "css/src/colours";

$input-height: 30px;
$control-height: 26px;
$icon-height: 1rem;

.container {
  @include display-flex;
  align-items: center;
  position: relative;
  border-radius: $input-height;
  width: $input-height * 1.75;
  height: $input-height;
  background-color: $grey;
  cursor: pointer;
  @include transition(background-color, 0.2s);

  &.active {
    background-color: $color-primary-light;

    .control {
      margin-left: 48%;
    }

    .icon {
      color: $color-primary-light;
    }
  }
}

.control {
  @include display-flex;
  @include box-shadow();
  @include transition(margin-left, 0.2s);
  align-items: center;
  border-radius: 50%;
  height: $control-height;
  width: $control-height;
  background-color: #ffffff;
  margin-left: 4%;
}

.icon {
  @include transition(color, 0.2s);
  color: $grey;
  width: $icon-height;
  height: $icon-height;
}
