@import "colours";
@import "mixins";

.block-grid {
  padding-left: 0;

  .block-grid-item {
    padding-bottom: 1rem;

    h4 {
      margin-bottom: 0.5rem;
    }
    span {
    }
  }
}
