@keyframes vehicle-status-pulse {
  0% {
    transform: scale(0.85);
    opacity: 0.1;
  }

  50% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(0.85);
    opacity: 0.1;
  }
}

@keyframes tool-pulse {
  0% {
    stroke-width: 1;
    stroke: currentColor;
    stroke-opacity: 1;
  }
  100% {
    stroke-width: 2.5vw;
    stroke: currentColor;
    stroke-opacity: 0;
  }
}

.animated-component {
  z-index: 2000;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;

  .card .card-content {
    padding: 0;
  }

  &.visible {
    pointer-events: all;
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    transition-delay: unset;
    opacity: 1;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }

  &.invisible {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
    transition-delay: 0.5s;
    transition: opacity 500ms 0ms;
  }
}
