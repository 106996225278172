@import "css/src/common";

%price-shared {
  margin: 0;
  font-weight: bold;
  display: block;
  font-size: 1rem;
}

.ShopItemPriceInline {
  @extend %price-shared;
  font-weight: normal;
}

.ShopItemPriceCompact {
  @extend %price-shared;
}

.ShopItemPriceNormal {
  @extend %price-shared;
  margin-bottom: $spacing-l;
  font-size: $header-2-font-size;
}
