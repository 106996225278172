@import "mixins";
@import "variables";

$card-box-shadow:
  0 8px 16px rgba(0, 0, 0, 0.08),
  0 2px 6px rgba(0, 0, 0, 0.04);
$card-border-radius: 4px;

.collection {
  .collection-item {
    margin-bottom: 20px;

    .actions {
      @include display-flex;
      justify-content: flex-end;
    }

    .actions button,
    .action a {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.card {
  position: relative;
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow 100ms;
  transition: -webkit-box-shadow 100ms;
  transition: box-shadow 100ms;
  border-radius: $card-border-radius;
  -webkit-box-shadow: $card-box-shadow;
  box-shadow: $card-box-shadow;

  &.full-height {
    height: 100%;

    & > .card-content {
      height: 100%;
    }
  }

  .card-content,
  .card-header {
    padding: $card-content-padding;
  }

  .card-header + .card-content {
    padding-top: 0;
  }

  .card-title {
    display: block;
    line-height: 32px;
    margin-bottom: 0.75rem;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -0.75px;
  }

  .card-footer {
    margin-top: 1rem;
  }
}

.statistic-card {
  margin-bottom: 1rem;

  .statistics-title {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.75px;
    line-height: 32px;
    margin: 0;
  }
  .statistics-number {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
}

.card-default {
  background-color: #fff;
  border-radius: 4px;
}

.card-valign-center {
  position: relative;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.card-small,
.card-medium,
.card-large {
  margin-left: auto;
  margin-right: auto;
}

.card-medium {
  max-width: 320px;
}

.card-medium {
  max-width: 450px;
}

.card-large {
  max-width: 650px;
}

.checkout-cards {
  margin-right: -1rem;
  .checkout-card-wrapper {
    width: 20%;
    float: left;
    text-align: center;
    padding-right: 1rem;
    margin-bottom: 1rem;

    @media screen and (max-width: $break-huge) {
      width: 25%;
    }
    @media screen and (max-width: $break-xl-large) {
      width: 33.3333%;
    }
    @media screen and (max-width: $break-large) {
      width: 50%;
    }
    @media screen and (max-width: $break-medium) {
      width: 100%;
    }

    .checkout-card {
      overflow: hidden;
      @include transition(all, 0.3s, cubic-bezier(0.25, 0.8, 0.25, 1));
      @include card-elevation(1);
      &:hover {
        cursor: pointer;
        @include card-elevation(3);
        -webkit-transform: translateY(-3px);
        -moz-transform: translateY(-3px);
        transform: translateY(-3px);
      }
    }

    p {
      margin-bottom: 0;
    }

    .queue-name,
    .value,
    .value-label {
      @include wrap;
    }

    .value {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .value-label {
      font-weight: 400;
      font-size: 0.86rem;
    }

    .queue-name {
      margin-bottom: 2rem;
    }

    .queue-directory,
    .queue-statistic {
      margin-bottom: 1rem;
    }

    .queue-name {
      font-weight: 600;
      font-size: 1.25rem;
    }
  }
}
