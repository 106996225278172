@import "css/src/colours";

$small: 1rem;
$medium: 1.25rem;
$large: 1.875rem;
$xl-large: 2.8125rem;

.icon {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  line-height: 1em;
}

.font {
  color: inherit;
  fill: currentColor;
}

.primary {
  fill: $blue;
}

.lightPrimary {
  fill: lighten($blue, 10%);
}

.theme {
  fill: $purple;
}

.success {
  fill: $green;
}

.lightSuccess {
  fill: lighten($green, 20%);
}

.danger {
  fill: $red;
}

.lightDanger {
  fill: lighten($red, 30%);
}

.warning {
  fill: $orange;
}

.lightWarning {
  fill: lighten($orange, 30%);
}

.white {
  fill: #ffffff;
}

.small {
  width: $small;
  height: $small;
}

.medium {
  width: $medium;
  height: $medium;
}

.large {
  width: $large;
  height: $large;
}

.xlarge {
  width: $xl-large;
  height: $xl-large;
}

.confettiContainer {
  position: relative;
  display: inline-block;
}

.confetti {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: -$large/2;
  margin-left: -$large/2;
}
