@import "colours";
@import "variables";

html {
  font-family: $default-font-family;
  font-size: $root-font-size;
  color: $font-color;
}

.text-success {
  color: $green;
}

.text-error,
.text-danger {
  color: $red;
}

.text-warning {
  color: $orange-light;
}

.text-muted {
  color: $grey;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.content-section-text {
  font-size: 1.3rem;
}

@mixin link {
  text-decoration: none;
}

// Anchor
a,
.link {
  @include link;
  color: $anchor;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $anchor-hover;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
  }
}

// Headers
.page-header h1 {
  font-size: 1.75rem;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  color: $header-text-color;
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: $header-1-font-size;
}
h2 {
  font-size: $header-2-font-size;
}
h3 {
  font-size: $header-3-font-size;
}
h4 {
  font-size: $header-4-font-size;
}

h4,
h5,
h6 {
  color: $subheading-text-color;
}

.subheading {
  color: $subheading-text-color;
  margin-bottom: 24px;
  font-weight: 500;
  max-width: 390px;
}

// Body text
pre:not(.code) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0 0 1.25rem;
}

pre:not(.code) {
  font-family: $default-font-family;
}

.headline {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.caption {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.capitalise {
  text-transform: capitalize !important;
}

.system-error {
  max-width: 100%;
  overflow-x: auto;
  font-size: 1rem;
}
