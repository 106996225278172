@import "../mixins";

.toolboxes-table {
  flex: auto;
  .table-header-row,
  .table-row {
    .table-cell:nth-child(1) {
      @include width(40%);
    }
    .table-cell:nth-child(2) {
      @include width(20%);
      text-transform: uppercase;
    }
    .table-cell:nth-child(3) {
      @include width(30%);
    }
    .table-cell:nth-child(4) {
      @include width(10%);
      text-align: right;
    }
  }
}
