@import "css/src/common";

.draggable {
  @include display-flex;
  width: 100%;
  flex-direction: column;
}

.draggableItem {
  @include display-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: -5rem;
  padding-left: 5rem;

  .draggableItemContent {
    flex: 1;
  }
}

:global {
  .sortable-handle {
    display: none;
    position: absolute;
    align-items: center;
    height: 100%;
    margin-left: -3.5rem;

    .sortable-handle-icon {
      color: $grey;
      cursor: pointer;
      margin-right: 0.75rem;
      position: relative;

      &:hover:after {
        display: block;
        content: "";
        height: 1.75rem;
        width: 1.75rem;
        background-color: grey;
        opacity: 0.13;
        border-radius: 3px;
        position: absolute;
        top: -0.375rem;
        left: -0.375rem;
      }
    }

    .sortable-handle-icon:last-child {
      margin-right: 0;
    }
  }
}

body > .draggableItem,
.draggableItem:hover {
  :global {
    .sortable-handle {
      @include display-flex;
    }
  }
}
