@import "css/src/variables";
@import "css/src/colours";

.title {
  margin-bottom: 2 * $major-padding;
}

.submitButton[type="submit"] {
  font-size: 1rem;
  padding: 1rem;
  line-height: 2em;
  border: none;
  background-color: $color-primary;
  width: 100%;
}
