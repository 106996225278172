@import "css/src/common";

.ShopItemPagePurchaseControlsNormal {
  @include display-flex;
  & > * {
    margin-right: $spacing-m;
  }

  width: 100%;
  padding: $spacing-m 0;
  border-top: 1px solid $table-border-color;
  border-bottom: 1px solid $table-border-color;
}
