@import "css/src/colours";

.trusted {
  display: flex;
  align-items: center;
  color: $green;

  &:hover,
  &:focus {
    color: $green;
  }

  .trustedIcon {
    margin-right: 3px;
    top: 1px;
  }
}
