@import "css/src/colours";
@import "css/src/mixins";
@import "css/src/variables";

$spacing-small: 0.75rem;

.dataGrid {
  width: 100%;
  display: grid;
  grid-row-gap: $spacing-small;
  grid-template-columns: 100%;

  @include tablet {
    grid-template-columns: 12rem auto;
  }

  .dataValue {
    margin: 0;
  }
}
