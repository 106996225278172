@import "css/src/common";

$sheet-font-size: 1rem;
$notice-background-color: rgba(235, 236, 237, 0.3);
$placeholder-background-color: rgb(242, 241, 238);
$placeholder-background-hover-color: rgb(232, 231, 228);

.sheet {
}

.sheetBlocks {
  @include display-flex;
  width: 100%;
  max-width: 100%;
}

.blockHandle {
  margin-left: -3.5rem;
}

@mixin blockCommon {
  font-size: $sheet-font-size;
  width: 100%;
  margin-top: 1px;
  margin-bottom: 1px;

  line-height: 1.5;

  h2,
  h3,
  h4 {
    line-height: 1.3;
  }
}

.blockActive [contenteditable]:empty:before {
  content: attr(placeholder);
  display: block;
  -webkit-text-fill-color: rgba(55, 53, 47, 0.4);
}

.flex {
  @include display-flex;
}

.emoji {
  font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", EmojiSymbols;
  line-height: 1em;
  font-size: 1em;
}

.textBlock {
  @include blockCommon;
  font-size: $sheet-font-size;
  padding: 0.125rem 0;
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;

  [contenteditable]:empty:before {
    content: attr(placeholder);
    display: block;
  }
}

.headerOneBlock {
  font-size: 1.5em;
  font-weight: bold;
}

.headerTwoBlock {
  font-size: 1.25em;
  font-weight: 500;
}

.headerThreeBlock {
  font-size: 1em;
  font-weight: 600;
}

.taskListBlock {
  @include blockCommon;
}

.notice {
  padding: 1.5rem;
  line-height: 1.5em;
  background-color: $notice-background-color;
  border-radius: $border-radius;
}

.contentPlaceholder {
  padding: 1rem;
  line-height: 1.5em;
  background-color: $notice-background-color;
  cursor: pointer;
  border-radius: $border-radius;
  @include transition(background-color, 100ms, linear);

  &:hover {
    background-color: $placeholder-background-hover-color;
  }
}

.toDoBlock {
  align-items: center;

  .textBlock {
    margin-left: 0.5em;
  }
}

.youTubeBlock,
.fileBlock,
.webLinkBlock {
  @include blockCommon;

  .blockPlaceholder {
    @include display-flex;
    align-items: center;
    color: $grey;
  }

  .blockPlaceholderIcon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.75rem;
  }
}

.youTubeMediaMenu,
.fileMediaMenu {
  padding: 0 1rem;
  margin: 0.5rem 0;
  & > button {
    width: 100%;
  }
}

.fileBlock {
  .fileSize {
    margin-left: 0.5rem;
    font-size: 0.875em;
    white-space: nowrap;
  }
}

.overlay {
  z-index: 9;
}

.blockMenu {
  position: fixed;
  z-index: 10;
  margin-left: 4rem;

  .blockMenuWrapper {
    @include display-flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;

    .blockMenuCard,
    .blockMediaMenuCard,
    .blockConfigMenuCard {
      position: relative;
      @include display-flex;
      flex-direction: column;
      min-width: 20rem;
      width: 100%;
      height: 100%;
      max-height: 40vh;
      padding: 0.5rem 0;
      overflow-y: auto;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;

      li {
        padding: 0.5rem 1rem;
        cursor: pointer;

        &:hover {
          @include transition(background-color, 0.2s);
          background-color: rgb(244, 245, 247);
        }
      }
    }

    .blockDescription {
      display: block;
      font-size: 0.875rem;
      opacity: 0.7;
    }

    .sectionTitle {
      font-size: 0.875rem;
      padding: 0 1rem;
      padding-top: 1rem;
      margin: 0.5rem 0;
      font-weight: bold;
      text-transform: uppercase;
      border-top: 1px solid $table-border-color;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }
    }

    .blockMediaMenuCard {
      .mediaMenuFormField {
        margin-bottom: 1rem;
      }
    }

    .blockConfigMenuCard {
      .icon {
        margin-right: 1rem;
        margin-left: 0;
      }

      li {
        @include display-flex;
        align-items: center;
        line-height: 1.5rem;
      }
    }
  }
}
