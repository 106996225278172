@import "colours";
@import "mixins";
@import "variables";

.pagination-controls {
  border: none;
  @include display-flex;

  & > * {
    flex-grow: 1;

    i.icon {
      height: auto;
    }
  }

  & > button {
    margin: 0 !important;
    min-width: 0;
  }

  & > p {
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    line-height: $tab-line-height;
    padding: 0 0.5rem;
    margin: 0;
    text-transform: uppercase;
    pointer-events: none;

    &.selected {
      border-bottom-color: $blue;
    }
  }
}

.pagination {
  line-height: 2rem;

  .icon {
    display: inline-block;
    width: auto;
    height: auto;
  }

  .loading-indicator {
    margin-left: 0.5rem;
  }

  .pagination-info {
    font-weight: 500;
  }

  .pagination-btn {
    margin-bottom: 0;
    margin-left: 0.5rem;
    padding: 0 0.75rem;
    min-width: 0;

    &:first-child {
      margin-left: 0;
    }

    &:disabled {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .icon.page-limit {
    font-size: 12px;
    margin: 0 0.25rem;
  }

  .current-limit {
    display: inline-block;
    color: $anchor;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .current-limit > .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;
  }

  .page-limit-popup {
    background-color: #e5e5e5;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    padding: 0.625em 0;
    position: absolute;
    display: block;
    z-index: 99;

    li {
      color: #333;
      display: block;
      line-height: 2em;
      padding: 0 2em 0 1em;
      white-space: nowrap;
      transition: color 200ms;

      &:not(.title):hover {
        color: $anchor-hover;
        cursor: pointer;
      }

      &.title {
        line-height: 2rem;
        font-weight: 500;
      }
    }
  }
}
