@import "css/src/variables";
@import "css/src/colours";
@import "css/src/mixins";

$input-hover-background-color: rgb(235, 236, 240);
$input-padding: 0.625rem;

.paragraph {
  white-space: normal;
}

.bold {
  font-weight: bold;
}

.inlineInputContainer {
  position: relative;
  padding-top: $input-padding;
  padding-bottom: $input-padding;
  min-height: 2.5rem;

  &.isEditing,
  &:hover,
  &:focus {
    @include transition(background-color, 0.3s);
    position: relative;
    border-radius: $border-radius;
    background-color: $input-hover-background-color;
    margin-left: -$input-padding;
    padding-left: $input-padding;
    margin-right: -$input-padding;
    padding-right: $input-padding;
  }

  .inlineInput {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background-color: transparent;
    padding: 0;
    line-height: inherit;

    &,
    &:hover,
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
}

.emulatePassword {
  -webkit-text-security: disc;
}

.inlineControls {
  position: absolute;
  right: 0;
  margin-top: 1rem;
  z-index: 1;

  .inlineButtonGroup {
    display: flex;
    flex-direction: row;
  }

  .inlineAction {
    border-radius: $border-radius;
    background-color: $input-hover-background-color !important;
    color: $font-color;
    margin-right: 4px;
    opacity: 1;

    &:hover {
      @include box-shadow;
      @include transition(box-shadow, 100ms, linear);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.inlineText {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
