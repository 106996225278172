@import "css/src/colours";
@import "css/src/variables";

.section {
  position: relative;
  padding-top: $default-padding;
  padding-bottom: $default-padding;

  &:first-child {
    padding-top: 0;
  }
}

.sectionTitle {
  margin-top: 0;
  margin-bottom: $card-content-padding;
  color: $subheading-text-color;
  font-weight: 500;
}

.sectionBorder {
  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid $table-border-color;
    margin-left: -$card-content-padding;
    width: calc(100% + #{2 * $card-content-padding});
  }
}

.section:last-child {
  padding-bottom: 0;
  &:after {
    display: none;
  }
}

.hasNoBottomPadding {
  padding-bottom: 0;
}
