//.pool {
//  display: grid;
//  grid-auto-columns: min-content;
//  grid-auto-rows: min-content;
//}

.pool {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
