@import "css/src/colours";
@import "css/src/mixins";
@import "css/src/variables";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  background-color: $page-secondary-background;
}

.stage {
  max-width: 600px;
  margin: 0 auto;
  min-height: 0;
  padding: 0 $minor-padding;
}

.card {
  margin: $minor-padding 0;
}

.header {
  margin-top: 0;
  margin-bottom: $minor-padding;

  .action {
    display: block;
    margin-top: $minor-padding;

    @include tablet {
      margin-top: 0;
      float: right;
    }
  }
}

.pageAction {
  display: inline-block;
  color: #ffffff;
  margin-bottom: 1rem;
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    color: #ffffff;
    border-bottom-color: #ffffff;
  }
}
