$spacing: 20px;

.inputGroup {
  justify-content: space-between;

  .portInput {
    flex: 0 0 calc(40% - #{$spacing});
    border-radius: 4px !important;
  }
  .typeInput {
    flex: 0 0 calc(40% - #{$spacing});
    border-radius: 4px !important;
  }
  .submitBtn {
    flex: 0 0 calc(20% - #{$spacing});
    white-space: nowrap;
    border-radius: 4px !important;
  }
}
