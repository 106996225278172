@import "css/src/colours";
@import "css/src/mixins";
@import "css/src/variables";

$popover-background-color: $grey-dark;

.Popover {
  display: inline-block;
  z-index: 10;
}

:global {
  .Popover {
    z-index: 10;
  }
}

.popOverContent {
  color: #ffffff;
  background-color: $popover-background-color;
  padding: $padding-s;
  border-radius: $border-radius;
}
