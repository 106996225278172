@import "css/src/mixins";

$gutter: 50px;

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.iFrameContainer {
  flex: 1 1 auto;

  & iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
}

.menuBar {
  @include box-shadow;
  display: flex;
  background-color: white;
  width: 100vw;
  justify-content: flex-end;

  a {
    display: inline-block;
    padding: 1rem;
  }
}
