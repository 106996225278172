$launch-app-height: 9.5rem;
$launch-app-width: 9.5rem;
$launch-app-width-small: 6.5rem;
$launch-app-height-small: 6.5rem;
$launch-app-symbol-size: 6.4rem;

@mixin launch-app-transition {
  -webkit-transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  -ms-transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@mixin launch-app-transform {
  -webkit-transform: translate(0, -0.5rem);
  -moz-transform: translate(0, -0.5rem);
  -ms-transform: translate(0, -0.5rem);
  -o-transform: translate(0, -0.5rem);
  transform: translate(0, -0.5rem);
}

.launch-pad {
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .launch-app {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
    text-align: center;
    vertical-align: top;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    &:before {
      border-radius: 2rem;
      content: "";
      display: block;
      height: $launch-app-height;
      width: $launch-app-width;
      background-color: white;
      -webkit-box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 1rem;
      @include launch-app-transition;
    }

    &:after {
      content: attr(data-symbol);
      display: block;
      position: absolute;
      top: 0;
      width: $launch-app-width;
      color: white;
      font-size: $launch-app-symbol-size;
      line-height: $launch-app-height;
      font-weight: 300;
      text-transform: uppercase;
      @include launch-app-transition;
    }

    &:hover {
      &:before,
      &:after {
        @include launch-app-transform;
      }
      &:before {
        -webkit-box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .launch-pad {
    height: auto;
    margin-bottom: 1rem;

    &:before {
      height: $launch-app-height-small;
      width: $launch-app-width-small;
    }
    &:after {
      line-height: $launch-app-height-small;
    }
  }
}
