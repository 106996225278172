@import "css/src/colours";
@import "css/src/variables";
@import "css/src/mixins";

.card {
  position: relative;
  background-color: $card-background-color;
  box-shadow: $shadow-sm;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: $card-border-radius;
  padding: $card-content-padding;
}

.title {
  margin-top: 0;
  color: $header-text-color;
  flex: 1 0 0;
}

.cardHeader {
  @include display-flex;
}
