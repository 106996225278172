@import "css/src/common";

.ProjectKanbanItem {
}

.header,
.progress,
.statusRow,
.footer {
  @include display-flex;
  margin-bottom: $spacing-s;

  &:last-child {
    margin-bottom: 0;
  }
}

.header {
  flex-direction: column;
}

.statusRow {
  justify-content: space-between;
  align-items: center;
}

.footer {
  justify-content: space-between;
}

.code {
  font-size: $font-size-s;
  font-weight: normal;
  margin-bottom: $spacing-xs;
}

.title {
}
