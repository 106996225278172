.header {
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0;
}

.list {
  padding-left: 0;

  li {
    white-space: nowrap;

    &.valid {
      text-decoration: line-through;
    }
  }
}
