@mixin flex($flex) {
  -webkit-flex: $flex;
  -ms-flex: $flex;
  flex: $flex;
}

@mixin width($width, $grow: 0, $shrink: 0) {
  -webkit-flex: $grow $shrink $width;
  -ms-flex: $grow $shrink $width;
  flex: $grow $shrink $width;
}

@mixin display-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin display-grid {
  display: -ms-grid;
  display: grid;
}

@mixin transition($prop, $time, $curve: ease-in-out) {
  -webkit-transition: $prop $time $curve;
  -moz-transition: $prop $time $curve;
  -ms-transition: $prop $time $curve;
  -o-transition: $prop $time $curve;
  transition: $prop $time $curve;
}

// Box shadow. Defaults: -1px 2px 6px rgba(0,0,0,0.16).
@mixin box-shadow($xOffset: -1px, $yOffset: 2px, $blur: 6px, $color: rgba(0, 0, 0, 0.16)) {
  -webkit-box-shadow: $xOffset $yOffset $blur $color;
  box-shadow: $xOffset $yOffset $blur $color;
}

@mixin no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@mixin card-elevation($level: 1) {
  @if $level == 1 {
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow:
      0 10px 20px rgba(0, 0, 0, 0.19),
      0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow:
      0 19px 38px rgba(0, 0, 0, 0.3),
      0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@mixin wrap() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin option {
  position: relative;
  display: block;
  color: #ffffff;
  line-height: 44px;
  height: 44px;
  padding: 0 2rem 0 4rem;
  margin: 0.25rem 1rem;
  border-radius: 4px;
  white-space: nowrap;
}

// Vertically align an element using flex.
@mixin vertical-flex-align {
  @include display-flex;
  flex-direction: column;
  justify-content: center;
}

// Horizontally align an element using flex.
@mixin horizontal-flex-align {
  @include display-flex;
  flex-direction: row;
  justify-content: center;
}

$tablet-width: 768px;
$desktop-width: 1200px;
$desktop-giant-width: 1800px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-giant {
  @media (min-width: #{$desktop-giant-width}) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}
