@import "css/src/variables";
@import "css/src/mixins";

.container {
  @include display-flex;
  min-height: 100vh;
}

.noticeBoard {
  display: none;

  @include tablet {
    display: block;
    flex-grow: 1;
  }

  .noticeCompanyLogo {
    background-size: cover;
    height: 100%;
    width: 100%;
  }
}

.pageContainer {
  @include horizontal-flex-align;
  background-color: #ffffff;
  flex: 100%;

  @include tablet {
    flex: 0 0 45%;
  }

  & > .page {
    @include vertical-flex-align;
    max-width: 28rem;
    width: 100%;
    flex-direction: column;
    margin: $major-padding;

    @include tablet {
      margin-top: $major-padding * 2;
      margin-bottom: $major-padding * 2;
    }
  }

  .companyLogoContainer {
    flex-grow: 0;
    text-align: center;

    @include tablet {
      text-align: left;
    }

    .companyLogo {
      max-width: 150px;
      width: 100%;
      height: auto;
    }
  }
  .content {
    @include vertical-flex-align;
    flex-grow: 1;
  }
}
