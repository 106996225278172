@import "css/src/common";

.ShopLayout {
  background-color: $page-secondary-background;
  overflow: hidden;
}

.ShopLayoutNavBar {
  position: relative;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07);
}

.ShopLayoutStage {
  @include display-flex;
  flex-grow: 1;
  flex-direction: row;
  background-color: $page-background;
  min-height: 100vh;
}
