@import "css/src/colours";
@import "css/src/mixins";
@import "css/src/variables";

$spacing-small: 2rem;

.dataList {
  width: 100%;
  @include display-flex;
  flex-direction: column;
}

.dataLabel {
  color: $font-color;
  padding-right: 1rem;
  align-self: center;
  min-width: 8rem;

  label {
    align-self: center;
    margin-bottom: 0;
  }
}

.dragHandle {
  margin-right: 1rem;
  cursor: pointer;
}

.dataListItemIndex {
  color: $grey;
}

.dataValue {
  flex: 1 1;
}

.removeIcon {
  margin-left: 1rem;
  cursor: pointer;
}

.dataListItem {
  @include display-flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid $input-border-color;
  border-bottom-width: 0;
  background-color: white;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-bottom-width: 1px;
  }
}
