@import "css/src/colours";

$spacing: 20px;
$min-height: 225px;

.panel {
  display: grid;
  grid-template-columns: 120px 290px;
  min-height: $min-height;
}

.groups,
.items {
  padding: 0 $spacing;
  list-style: none;
  margin: 0;

  li {
    position: relative;
    cursor: pointer;
  }
}

.groups {
  border-right: 1px solid $list-border;

  li {
    line-height: 52px;
  }

  li.active::before {
    display: block;
    content: "";
    position: absolute;
    left: -$spacing;
    width: 3px;
    height: 100%;
    background-color: $blue;
  }
}

.items {
  padding-top: 10px;
  padding-bottom: 10px;

  li {
    line-height: 2rem;
  }
}
