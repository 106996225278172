$background-color: rgba(0, 0, 0, 0.1);
$background-color-dark: rgba(0, 0, 0, 0.4);
$transition: all, 300ms, ease-out;

// z-index of -1 so that the parent can specify the height and the overlay will not be above the parent
$zIndex: -1;

.overlay {
  visibility: hidden;
  cursor: default;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: $background-color;
  z-index: $zIndex;

  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &.dark {
    background-color: $background-color-dark;
  }

  &.transparent {
    background-color: transparent;
  }
}
