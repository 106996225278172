@import "css/src/mixins";

.wrapper {
  display: flex;
  flex-direction: row;
}

.label {
  @include vertical-flex-align;
  margin-right: 1rem;
}

.selectInput {
  min-width: 5rem;
}
