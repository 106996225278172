@import "css/src/mixins";
@import "css/src/variables";
@import "css/src/colours";

$section-width: 500px;
$empty-section-color: #f5f5f5;
$spacing-large: 50px;
$spacing-medium: 30px;
$spacing-small: 20px;
$text-line-height: 3rem;

.builder {
  @include display-flex;
  flex-direction: column;
}

.canvas {
  position: relative;
  margin: 0 auto;
}

.sectionGroup {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 240px 240px;
  grid-column-gap: $spacing-small;
}

.sectionEditIcon {
  position: absolute;
  left: -60px;
}

.sectionPlaceholder {
  cursor: pointer;
}

.tablePlaceholder {
}

.generatingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;

  p {
    color: $grey;
  }

  .loadingIconContainer {
    position: relative;
    width: 70px;
    height: 82px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .generatingIcon {
    position: absolute;
    fill: #b5d0ff;
  }

  .generatedIcon {
    position: relative;
    margin: 0 auto;
    fill: $icon-green;
    width: 50px;
    height: 50px;
  }

  .loadingIcon {
    position: relative;
    fill: white;
    margin: 0 auto;
    width: 36px;
    height: 36px;
  }
}

.sectionTextValue {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre {
    line-height: $text-line-height;
  }

  .textInlineInput {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.svgPlaceholder {
  width: $section-width;
  cursor: pointer;

  &.text {
    height: 3rem;
  }

  &.chart {
    height: 5rem;
  }

  &.table {
    height: 10rem;
  }

  .svgPlaceholderBackground {
    width: 100%;
    height: 100%;
    fill: #f5f5f5;
    border-radius: $border-radius;
  }

  .svgPlaceholderBorder {
    fill: none;
    stroke: #c6c6c6;
    stroke-width: 1px;
    stroke-dasharray: 6px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    transform: translate(2px, 2px);
    border-radius: $border-radius;
  }

  .svgPlaceholderGroup {
    transform: translate(50%, 50%);
  }

  .svgPlaceholderIconWrapper,
  .svgPlaceholderIcon {
    width: 2rem;
    height: 2rem;
  }

  .svgPlaceholderText,
  .svgPlaceholderIcon {
    fill: #525866;
    font-size: 1rem;
    text-anchor: middle;
  }
}
