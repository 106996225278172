@import "../mixins";
@import "../variables";

.dashboard {
  .welcome-title {
    text-align: center;
    margin-bottom: 0.5rem;
    & + p {
      text-align: center;
    }
  }

  .notice-card .card-content {
    @include display-flex;
    justify-content: space-between;

    span {
      line-height: $button-line-height;
    }
  }

  .activity-chart-wrapper {
  }
}

.event-tooltip {
  display: inline-block;

  .card-content {
    padding: 0.75rem;
  }

  p {
    margin: 0;
  }
}
