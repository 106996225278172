@import "css/src/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
  padding: 0 $default-padding;
  text-align: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
}

.action {
  margin-top: 60px;
}
