.container {
  position: relative;

  .https,
  .domain {
    position: absolute;
    top: 50%;
    margin-top: -0.625rem;
  }

  .https {
    left: 1rem;
  }

  .domain {
    right: 1rem;
  }

  .input {
    padding-left: 5rem;
    padding-right: 8rem;
  }
}
