@import "css/src/common";

.taskList {
  width: 100%;

  .header {
    @include display-flex;
    flex-direction: row;
    align-items: center;
  }

  .title {
    display: block;
    font-weight: bold;
    flex: 1;
    margin-bottom: 0.5rem;
    margin-right: 1.5rem;
  }
}

.taskListItem {
  cursor: pointer;

  &:hover {
    background-color: $table-cell-header-background-color;
  }

  .taskListContent {
    @include display-flex;
    flex-direction: row;
    align-items: center;

    .taskListTitle {
      flex: 1;
      margin-right: 1.5rem;
    }
  }
}
