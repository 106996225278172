.dropdown {
  position: relative;
}

.overlay {
  z-index: 2;
}

.dropdownPanel.panel {
  position: absolute;
  padding: 0;
  z-index: 9;
  top: 1rem;
  left: 1rem;
}
