@import "css/src/variables";
@import "css/src/colours";
@import "css/src/mixins";

$stage-offset: $minor-padding;
$dashboard-background-color: $page-background;

.layout {
  @include display-flex;
  flex-direction: row;
  min-height: 100vh;
}

.stage {
  @include display-flex;
  flex-grow: 1;
  flex-direction: row;
  background-color: $dashboard-background-color;
  min-height: 100vh;
}
