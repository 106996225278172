@import "css/src/common";

.container {
  @media only screen and (min-width: 768px) {
    margin-top: $major-padding;
  }
}

.noMarginTop {
  margin-top: 0;
}

.header {
  @include display-flex;
  margin: 0 0 $major-padding;
  justify-content: flex-start;
  flex-direction: column;
  gap: $major-padding;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}

.titleWrapper {
  flex: 1;

  .title {
    margin: 0 0 0.5rem;
  }
}

.action {
  @include display-flex;
  gap: 1rem;
}

.breadcrumbMenu {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: $minor-padding;

  li {
    display: inline-block;

    a,
    span {
      color: $font-color;

      &:hover {
        color: $font-color;
      }
    }
  }

  li:not(:last-child) {
    & a::after,
    & span::after {
      display: inline-block;
      content: ">";
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
