@import "css/src/variables";

.alreadyHaveAccount {
  display: block;
  text-align: center;
  margin-top: $major-padding;
}

.successIcon {
  margin-bottom: $default-padding;
}

.confirmationText {
  line-height: 2.5;
}
