@import "css/src/variables";
@import "css/src/colours";
@import "css/src/mixins";

// Tables
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 0.5rem;
$table-cell-header-font-size: 1rem;
$table-cell-header-font-weight: 500;

.table {
  min-width: 100%;
}

.tableBody {
}

.tableRow {
  &:last-child {
    .tableCell {
      border-bottom: 0;
    }
  }
}

.tableHeader {
  background-color: $table-cell-header-background-color;
  border-bottom: 1px solid $table-border-color;
}

.tableCell {
  padding: $table-cell-padding-y $table-cell-padding-x;
  border-bottom: 1px solid $table-border-color;

  &.cellIcon {
    padding: 0 $table-cell-padding-x;
  }

  &:first-child {
    padding-left: 2 * $table-cell-padding-x;
  }
  &:last-child {
    padding-right: 2 * $table-cell-padding-x;
  }
}

.tableHeadCell {
  text-align: left;
  color: $table-cell-header-color;
  font-size: $table-cell-header-font-size;
  font-weight: $table-cell-header-font-weight;

  &.numericalCell {
    text-align: right;
  }
  &.active {
    color: $table-cell-header-color-active;
  }
  &.buttonRemoveColumn {
    width: 60px;
  }
}

.paginationButtons {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.paginationWrapper {
  @include display-flex;
  justify-content: flex-end;
  padding-top: $card-content-padding;

  .limitSelector,
  .currentPage {
    margin-right: 1rem;
  }

  .currentPage {
    @include vertical-flex-align;
  }
}

.tableNavWrapper {
  @include display-flex;
  justify-content: space-between;
  padding-top: $minor-padding;
  padding-bottom: $minor-padding;

  .limitSelector,
  .currentPage {
    margin-right: 1rem;
  }

  .navPaging {
    @include display-flex;
    align-items: center;
    & > * {
      margin-left: 1rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .navPagingCount {
    font-weight: bold;
  }

  .queryInput {
    margin-left: 1rem;
    max-width: 22rem;
    background-color: $page-secondary-background;
  }
}

.rowNotice {
  text-align: center;
  color: $grey;
}

.rowNoticeCell {
  & > span:first-child {
    margin-right: 1rem;
  }
}

.pageSummary {
  @include display-flex;
  justify-content: flex-start;
  padding: $default-padding 0;
}
