@import "css/src/variables";
@import "css/src/mixins";
@import "css/src/colours";

$sidebar-background-color: $page-secondary-background;
$menu-item-background-color-active: #ebecf0;
$menu-item-image-background-color: rgb(244, 245, 247);
$resize-bar-size: 2rem;

.sidebars {
  display: none;
  top: 0;
  left: 0;
  height: 100vh;
  position: absolute;
  z-index: $sidebar-z-index;

  @include tablet {
    @include display-flex;
    position: sticky;
  }
}

.sidebar {
  position: relative;
  width: $sidebar-width;
  background-color: $sidebar-background-color;
  --animate-duration: 0.4s;
  @include transition(width, 0.2s, ease-in-out);
}

.sidebarNav {
  @include display-flex;
  flex-direction: column;
  padding: 1.5rem;
  height: 100%;
  overflow: auto;
}

.resizeBar {
  border-left: 1px solid $input-border-color;
  height: 100%;
  width: 1px;
  position: absolute;
  right: -$resize-bar-size/2;
  top: 0;
  bottom: 0;
  padding-left: $resize-bar-size/2;
  @include transition(border-color, 0.2s, ease);

  .resizeBarHandle {
    display: none;
    cursor: pointer;
    width: $resize-bar-size;
    height: $resize-bar-size;
    border-radius: 50%;
    top: 4rem;
    @include box-shadow;
    z-index: 1;
    left: -$resize-bar-size;
    position: relative;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    @include transition(all, 100ms, linear);

    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #ffffff;
    }

    .resizeBarHandleIcon {
      width: 1rem;
      height: 1rem;
    }
  }

  &.resizeBarCollapsable {
    cursor: ew-resize;

    .resizeBarHandle {
      display: flex;
      align-items: center;
      opacity: 0;
    }

    &:hover {
      border-color: lighten($color-primary, 20%);
      .resizeBarHandle {
        opacity: 1;
      }
    }
  }
}

.sidebarHeader {
  text-align: center;
  margin-top: $default-padding;
  margin-bottom: $default-padding;
}

.menuSeparator {
  height: 1px;
  background-color: darken($menu-item-background-color-active, 5%);
  margin-top: 1rem;
  margin-bottom: 1rem;

  &.noMarginTop {
    margin-top: 0;
  }
  &.noMarginBottom {
    margin-bottom: 0;
  }
}

.actionButton {
  width: 100%;
}

.menuItem {
  display: block;
  position: relative;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  color: $font-color;
  margin-bottom: 0.25rem;

  &:hover,
  &.active {
    background-color: $menu-item-background-color-active;
  }

  .menuItemContent {
    @include display-flex;
    align-items: center;
    color: inherit;

    .menuText {
      margin-left: 1rem;
    }

    &.alignLeft {
      flex-direction: row-reverse;

      .menuText {
        margin-left: 0;
        margin-right: 1rem;
      }
    }
  }

  .menuItemIcon {
    color: $font-color;
    border: 1px solid transparent;
  }

  .menuItemImage,
  .menuItemIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    background-color: $menu-item-image-background-color;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0.25rem;
  }

  .menuText {
    @include display-flex;
    color: inherit;
    flex: 1;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    justify-content: space-between;
    align-items: center;
  }

  &.active {
    .menuItemIcon,
    .menuText {
      color: $color-primary;
    }
  }
}

.mainSidebar {
  z-index: 1;
  width: $sidebar-main-width-large;

  &.collapsed {
    width: $sidebar-main-width-small;
  }

  .logo {
    text-align: center;
    padding: 1rem;
  }

  .mainSidebarContent {
    @include display-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .mainSideBarPages {
    margin-top: 1rem;
    max-height: 50vh;
    overflow-y: auto;

    // Overrides the default menuItem look
    .menuItem {
      &:hover,
      &.active {
        background-color: transparent;

        .menuItemIcon,
        .menuItemImage {
          outline: none;
          border: 1px solid $input-border-focus;
        }
      }
      &.active {
        .menuItemIcon,
        .menuItemImage {
          box-shadow: 0 0 0 4px rgba(133, 77, 255, 0.2);
        }
      }
    }
  }

  .mainSideBarFooter {
  }
}

.sideBarMenuItem {
  svg {
    fill: $color-primary;
    color: $color-primary;
  }
}

.sidebarImageHeader {
  text-align: center;

  h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .storagePortrait {
    margin: 0 auto;
  }

  .assetImage {
    display: inline-block;
    max-width: 10rem;
    width: 100%;
    height: auto;
  }
}

.upsellStatusPill {
  //margin-left: .5rem;
}
