@import "css/src/variables";
@import "css/src/colours";
@import "css/src/mixins";

.card {
  list-style-type: none;
  margin: 0.5rem 0;
  padding: 1rem;
  background: #fff;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    @include card-elevation(1);
  }
}

.content {
  margin-bottom: 1rem;
  .name {
    display: block;
    text-transform: capitalize;
  }

  & a:hover {
    text-decoration: underline;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .expiry {
    color: $grey;
    font-size: 12px;
  }
}

.methods {
  .icon {
    width: 1.25rem;
    margin-right: 0.25rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
