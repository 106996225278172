@import "css/src/common";

$progress-ring-stroke: $color-primary;

.progressRing {
  stroke-linecap: round;

  .ringStrokeBackground {
    stroke: $input-border-color;
  }

  .ringStroke {
    stroke: $progress-ring-stroke;
    stroke-opacity: 0.7;
  }

  .progressRingCircle {
    fill: transparent;
  }
}
