@import "mixins";

.captions {
  @include display-flex;
  justify-content: space-between;

  &.items-5 > div {
    flex: 0 0 20%;
    text-align: center;
  }

  &.items-4 > div {
    flex: 0 0 25%;
    text-align: center;
  }

  &.items-3 > div {
    flex: 0 0 33.33333%;
    text-align: center;
  }

  &.items-2 > div {
    flex: 0 0 50%;
    text-align: center;
  }

  .headline {
    font-size: 26px;
    margin-bottom: 0.5rem;
  }

  .caption {
    margin-bottom: 0;
  }

  a {
    font-size: 0.875rem;
  }
}
