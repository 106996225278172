@import "css/src/variables";
@import "css/src/mixins";
@import "css/src/colours";

.options {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.option {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 1rem;
  padding-right: 3rem;
  cursor: pointer;
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: auto;
  @include no-select;

  &.active {
    border-color: $icon-green;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .name {
    display: block;
  }

  .description {
    display: inline-block;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    max-width: 15rem;
  }
}

.selectIcon {
  position: absolute;
  height: 1rem;
  width: 1rem;
  fill: $icon-green;
  top: 50%;
  margin-top: -0.5rem;
  right: 1rem;
}
