// Colours
$red: #e41e13;
$red-dark: rgb(182, 0, 0);
$blue: #488aff;
$blue-dark: #138fb6;
$green: #2ac845;
$teal: #48c0b7;
$purple: #854dff;
$purple-dark: rgb(6, 21, 81);
$orange: #f57c00;
$orange-light: #feb47b;
$grey: #a6a6a6;
$grey-dark: #1d2026;
$grey-light: #c6c6c6;
$white: #ffffff;

// Theme
$color-primary: $purple;
$color-primary-light: lighten($color-primary, 5%);
$color-primary-dark: darken($color-primary, 10%);
$color-secondary: $purple;

// Generic
$font-color: rgb(51, 65, 85); /* text-slate-700 */
$font-color-light: #a6a6a6;

// Typography
$anchor: $color-primary;
$anchor-hover: $anchor;

// Page
$page-background: white;
$page-secondary-background: #f9fafc;
$page-tertiary-background: rgba(125, 171, 255, 0.2);

// Navigation
$navbar-background: $grey-dark;
$navbar-text: #ffffff;
$navbar-option-selected: $color-primary;

// Buttons
$button-background: $color-primary;
$button-background-hover: $color-primary-light;
$button-text: $white;
$button-text-hover: $white;
$header-button-background: $blue;
$header-button-background-hover: $blue-dark;
$header-button-text: $white;
$header-button-text-hover: $white;
$button-secondary-background: #f2f2f2;
$button-secondary-border: #d9d9d9;
$button-secondary-text: $grey-dark;

// Tables
$table-border-color: #eeeeee;
$table-row-hover-color: rgb(244, 245, 247);
$table-cell-header-color: #626266;
$table-cell-header-background-color: rgb(249, 250, 251);
$table-cell-header-color-active: #333333;

// List
$list-border: #e3e3e3;
$list-item-background: #fff;
$list-item-background-hover: #2cbce9;
$list-item-text: #737373;
$list-item-text-hover: #fff;

// Inputs
$input-border-color: #eeeeee;
$input-border-focus: $purple;
$input-border-error: #e41e13;
$input-background-color: #ffffff;
$input-text-color: $font-color;
$input-placeholder-color: #a6a6a6;
$input-disabled-text-color: #a6a6a6;
$input-hover-background-color: rgb(235, 236, 240);
$progress-bar-background-color: #ecedf1;
$progress-bar-success-color: #36db52;
$progress-bar-warning-color: $orange;
$progress-bar-danger-color: $red;
$progress-bar-border-radius: 0.5rem;

// Login
$login-button: $button-background;
$login-button-hover: $button-background-hover;

// Modal
$modal-header-background: $navbar-background;
$modal-submit-background: $blue;
$modal-title-text-colour: $font-color;
$modal-text-colour: $font-color;
$modal-input-border-colour: #f2f2f2;
$modal-close-color: rgba(50, 50, 50, 0.87);

// Headings
$header-text-color: #333333;
$subheading-text-color: $font-color;

// Icons
$icon-green: #16d91b;
$icon-red: #fe3b2f;
$icon-blue: #2cbce9;
$icon-orange: #f57c00;

// Cards
$card-background-color: #ffffff;
$card-secondary-background-color: #525866;
$card-secondary-color: #ffffff;
$card-box-shadow-color: rgba(0, 0, 0, 0.12);

// Kanban
$kanban-column-hover-background-color: $page-tertiary-background;
