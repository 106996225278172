@import "css/src/mixins";
@import "css/src/colours";
@import "css/src/variables";

.fieldGroup {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
  }

  .input,
  .selectInput {
    flex: 1;
  }

  &.withAddon {
    .input {
      border-radius: $button-border-radius 0 0 $button-border-radius;
    }
    button {
      border-radius: 0 $button-border-radius $button-border-radius 0;
    }
  }
}
