@import "css/src/variables";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.small {
    width: 5rem;
    height: 5rem;
  }

  &.medium {
    width: 10rem;
    height: 10rem;
  }

  &.large {
    width: 15rem;
    height: 15rem;
  }
}

.modelBase,
.drawerSlice {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
}

.modelBase {
  position: relative;
}

.drawerSlice {
  position: absolute;
}

.shadow {
  position: absolute;
  height: auto;
  width: 120%;
  bottom: -5%;
  left: -10%;
}
