@import "css/src/colours";
@import "css/src/variables";
@import "css/src/mixins";

$card-elevation-1: -1px 2px 6px $card-box-shadow-color;
$stage-card-padding: 50px;

.separator {
  height: 1px;
  background: #e1e6eb;
  margin: 50px 0;
}

.section {
}
