@import "css/src/colours";
@import "css/src/variables";

$border-bottom-color: #dadce0;
$border-bottom-active-color: #854dff;

.list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $border-bottom-color;
  margin-bottom: 2rem;

  li {
    border-bottom: 3px solid transparent;
    padding: 0.5rem 0;
    position: relative;
    top: 1px;
    font-weight: 600;
    cursor: pointer;
    margin-right: $default-padding;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-bottom: 3px solid $border-bottom-active-color;
    }
  }
}

.stageTabs {
  .list {
    //margin-left: -$stage-card-padding;
    //margin-right: -$stage-card-padding;
    //padding-left: $stage-card-padding;
    //padding-right: $stage-card-padding;
  }
}
