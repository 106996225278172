@import "css/src/mixins";

$overlay-z-index: 9;

.container {
  @include display-flex;
  position: relative;
}

.preview {
  display: inline-block;
  width: 50px;
  height: 2rem;
  background-color: #a6a6a6;
  border-radius: 4px;
  cursor: pointer;
  top: 0;
  left: 0;
}

.text {
  display: inline-block;
  margin-left: 1rem;
  line-height: 2rem;
}

.overlay {
  z-index: $overlay-z-index;
}

.picker {
  display: none;
  z-index: $overlay-z-index + 1;
  position: absolute !important;
  left: -90px;
  bottom: 3rem;
  -webkit-box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.16) !important;
  box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.16) !important;

  &.pickerVisible {
    display: block;
  }
}
