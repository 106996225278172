@import "./colours";
@import "./mixins";
@import "./variables";

$modal-width: 300px;

.modal-wrapper {
  position: fixed;
  top: $modal-wrapper-padding;
  bottom: $modal-wrapper-padding;
  left: $modal-wrapper-padding;
  right: $modal-wrapper-padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-close {
  position: absolute;
  cursor: pointer;
  right: 32px;
  top: 28px;
  text-align: center;

  .icon {
    font-size: 36px;
    color: $input-border-color;
    transition: color 100ms ease-in;

    &:hover {
      color: $color-primary;
    }
  }
  span {
    display: block;
    font-weight: 700;
    color: $font-color-light;
  }
}

.modal {
  background-color: white;
  color: $font-color;
  left: 0;
  right: 0;
  z-index: 2000;
  margin-left: auto;
  margin-right: auto;

  label {
    display: block;
    padding: 0.6875em 0.6875em 0.6875em 0;
  }

  .row {
    margin-bottom: 0;
  }

  &.match-container-size {
    width: 100%;
    height: 100%;
  }

  .modal-title {
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.modal-nav {
  @include display-flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  background: $modal-header-background;
  color: white;
  line-height: 3rem;
  padding: 0 0.75rem;

  & > .icon {
    display: inline-block;
    margin: 0 0.5rem 0 0;
    height: 3rem;
  }

  .modal-close {
    background: linear-gradient(45deg, transparent 46%, white 46%, white 54%, transparent 54%),
      linear-gradient(-45deg, transparent 46%, white 46%, white 54%, transparent 54%);
    height: 1.25em;
    width: 1.25em;
    cursor: pointer;
    margin-left: auto;
  }
}

.modal-header {
  padding: 2em 2em 1.375em 2em;
  text-align: center;

  & h1,
  & h2,
  & h3 {
    color: $modal-title-text-colour;
    font-weight: 900;
    font-size: 2rem;
  }

  & > .icon {
    color: black;
    display: block;
    font-size: 3.5rem;
    height: 1em;
    width: 1em;
  }
}

@media only screen and (min-width: 601px) {
  $modal-width: 350px;

  .modal {
    width: $modal-width;
  }
  .modal.large {
    width: $modal-width * 1.333;
  }
}

@media only screen and (min-width: 993px) {
  $modal-width: 450px;

  .modal {
    width: $modal-width;
  }

  .modal.large {
    width: $modal-width * 1.5;
  }
}

.modal-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.modal.modal-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  padding: 32px;
  box-shadow: none;
}

.modal.modal-fullscreen form {
  max-width: 500px;
  margin: 80px auto;
}

.modal.animated {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
}
