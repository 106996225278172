@import "css/src/common";

.Group {
  padding: 1rem;
  border-radius: $border-radius;
  border: 1px solid $input-border-color;
}

.verticalAlign {
  @include display-flex;
  align-items: center;
}

.spaceBetween {
  @include display-flex;
  justify-content: space-between;
}
