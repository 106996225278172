//.swimlane {
//  display: contents;
//}

.swimlane {
  min-width: 15rem;
  flex: 1;
  background: #f2f2f3;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
}
