@import "css/src/variables";
@import "css/src/colours";
@import "css/src/mixins";

.modal {
  @include display-flex;
  max-width: 50rem;
  width: 100%;
  min-height: 35rem;
  flex-direction: row;
}

.form {
  @include display-flex;
  flex-direction: column;
  flex: 1;
}

.modalContent {
  @include display-flex;
  flex-direction: column;
  flex: 1;
}

.toolboxContainer {
  flex: 0 0 40%;
  @include display-flex;
  align-items: center;
  background-color: rgb(243, 244, 246);

  .toolbox {
    margin: 0 auto;
  }
}

.orCreate {
}
