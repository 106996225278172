@import "css/src/mixins";
@import "css/src/colours";
@import "css/src/variables";

$small: 30px;
$medium: 50px;
$large: 100px;

$default-background-color: white;

.avatar {
  @include no-select;
  @include display-flex;
  position: relative;
  background-color: $default-background-color;
  border-radius: $border-radius * 2;

  overflow: hidden;
  justify-content: center;
  flex-direction: column;
  color: inherit;

  .alt {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: inherit;
    font-size: 0.875em;
    background-color: rgb(240, 240, 240);
  }
}
.clickable {
  background-color: rgb(240, 240, 240);
}

.size-s {
  height: $small;
  width: $small;
  font-size: 14px;
}

.size-m {
  height: $medium;
  width: $medium;
  font-size: 16px;
}

.size-l {
  height: $large;
  width: $large;
  font-size: 20px;
}
