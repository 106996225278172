@import "../mixins";

.tools-table {
  flex: auto;
  .table-header-row,
  .table-row {
    .table-cell:nth-child(1) {
      @include width(45%);
    }
    .table-cell:nth-child(2) {
      @include width(15%);
      text-transform: capitalize;
    }
    .table-cell:nth-child(3) {
      @include width(20%);
    }
    .table-cell:nth-child(4) {
      @include width(20%);
    }
  }
}
