@import "css/src/variables";
@import "css/src/colours";

$graphic-width: 10rem;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    color: $font-color;
  }
}

.logo,
.graphic {
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 2 * $major-padding;
}

.graphic {
  margin-top: $default-padding;

  & img {
    max-width: $graphic-width;
    width: 100%;
  }
}

.loginButton {
  font-size: 1rem;
  padding: 1rem;
  margin-top: $major-padding - $minor-padding;
  margin-bottom: $major-padding;
  border: none;

  &.loginButtonIdle {
    background-color: $color-primary;
  }
}

.forgotPassword {
  display: inline-block;
  margin-top: 1rem;
}

.disclaimer {
  color: $font-color;
  font-size: 0.875rem;
  text-align: center;
  margin-top: $default-padding;
}

.inputGroups {
  position: relative;
}
