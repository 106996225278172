@import "css/src/common";

.ShopItemDocument {
  @include display-flex;
  align-items: center;
}

.deleteIcon {
  margin-left: $padding-xs;
}
