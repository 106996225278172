.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 11;
}

.genericModal {
  max-width: 20rem;
  min-height: 10rem;
}

.wideModal {
  max-width: 40rem;
  min-height: 10rem;
}
