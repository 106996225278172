@import "colours";
@import "variables";
@import "mixins";

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --animate-delay: 0.5s;
}

* {
  -webkit-font-smoothing: antialiased;
}

html {
  -webkit-tap-highlight-color: transparent;
  background-color: $page-background;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

[contenteditable] {
  -webkit-tap-highlight-color: transparent;
}

*,
*:focus {
  outline: 0;
}

b,
strong {
  font-weight: 600;
}

small,
h5 {
  font-size: 0.875em;
}

th,
tr,
td {
  position: relative;
}

fieldset {
  border: none;
}

body {
  overflow-x: hidden;
}

button > .icon {
  display: inline-block;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-center {
  text-align: center;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.align-center {
  @include display-flex;
  align-items: center;
}

nav.nav {
  z-index: 9;
}

ul {
  padding-left: 1rem;
}

.hidden {
  display: none !important;
}

select {
  cursor: pointer;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

table {
  border-collapse: collapse;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $input-placeholder-color;
  /* Firefox */
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $input-placeholder-color;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $input-placeholder-color;
}
