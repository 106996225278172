.atlaskit-portal {
  & > div {
    bottom: 3.5rem;
    left: 4rem;

    & > div {
      width: 28.5rem !important;

      & > div {
        padding: 1.25rem;
        border-radius: 0.25rem;

        // Text
        & > div:first-child {
          height: 2.2rem;

          & > span:not(:first-child) {
            padding: 0 0 0 1.25rem;
          }

          & button > span {
            height: 2.2rem;
            width: 2.2rem;

            & > svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
