@import "css/src/common";

.ShopNavBarContainer {
  position: relative;
}

.ShopNavBar {
  padding: $minor-padding $spacing-l;
  text-align: center;

  @include tablet {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-l $spacing-xl;
  }
}

.ShopNavBarLogoContainer {
  text-align: center;
}

.ShopNavBarLogo {
  max-width: 10rem;
}

.navBarMenu {
  display: none;
  align-items: center;
  margin: 0;

  @include tablet {
    @include display-flex;
  }

  .menuLink {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
