@import "css/src/common";

.queryFilter {
  background-color: $page-secondary-background;
}

.navActions {
  @include display-flex;
}

.StorageDrawerInventory {
}

.StorageDrawerHeader {
  @include display-flex;
  align-items: center;
  justify-content: space-between;
}

.StorageDrawerInventoryItem {
  @include display-flex;
  align-items: center;
  justify-content: space-between;
}

.StorageDrawerInventoryItemToolbox {
  @include display-flex;
  align-items: center;
  & > * {
    margin-right: $spacing-s;

    &:last-child {
      margin-right: 0;
    }
  }
}
