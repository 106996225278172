@import "css/src/common";

$button-padding: 0.625rem;
$icon-size: 1.25rem;

$button-text-color: $font-color;
$button-hover-background-color: rgba(0, 0, 0, 0.08);
$button-hover-text-color: $font-color;
$button-disabled-text-color: #a6a6a6;

.button {
  background: transparent;
  padding: $button-padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  border-radius: 50%;
  outline: none;

  .icon {
    color: $button-hover-text-color;
  }

  &:not(:disabled):hover {
    @include transition(background-color, 100ms, ease-in-out);
    background-color: $button-hover-background-color;
    cursor: pointer;
  }

  &:disabled .icon {
    fill: $button-disabled-text-color;
  }
}
