@import "css/src/variables";

.disclaimer {
  display: block;
  margin: 0 auto;
  font-size: 0.875rem;
  text-align: center;
  width: 80%;
}

.alreadyHaveAccount {
  display: block;
  text-align: center;
  margin-top: $major-padding;
}

.successIcon {
  margin-bottom: $default-padding;
}

.confirmationText {
  line-height: 2.5;
}

.customerTypes {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 1rem;
}

.nextSteps {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 1rem;
}
