@import "css/src/common";

.KanbanItem {
  border: 1px solid $table-border-color;
  padding: $spacing-xs;
  border-radius: $border-radius;
  cursor: move !important;
  background-color: white;
  margin-bottom: $spacing-xs;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $table-row-hover-color;
  }
}
