@import "css/src/mixins";
@import "css/src/variables";

$spacing-large: 50px;
$spacing-medium: 30px;
$spacing-small: 20px;
$container-background-color: #f5f5f5;

.container {
  display: flex;
  flex-direction: column;
  background-color: $container-background-color;
  border-radius: $border-radius;
  padding: $spacing-large;
  justify-content: center;
}

.header {
  text-align: center;
  h1 {
    margin-top: 0;
  }
}

.templateSelector {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .templateSelectorButton {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .templatePreviewContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 $default-padding $spacing-medium;

    @include desktop-giant {
      margin-bottom: $spacing-large;
    }
  }

  .templatePreview {
    width: 120px;
    height: 180px;

    @include desktop-giant {
      width: 200px;
      height: 300px;
    }
  }

  .templateSuccessIcon {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    background-color: white;
    padding: 4px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    @include box-shadow;
  }
}

.themeSelector {
  text-align: center;
  margin: 0 $default-padding $spacing-medium;

  @include desktop-giant {
    margin-bottom: $spacing-large;
  }

  .themes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .theme {
      position: relative;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 11px;
      cursor: pointer;
      margin: 0 10px;

      .themeIcon {
        fill: #ffffff;
        width: 22px;
        height: 16px;
      }
    }
  }
}

.actionsContainer,
.successActionsContainer {
  text-align: center;
}

.successActionsContainer {
  margin-top: $spacing-medium;
}
