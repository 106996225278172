@import "colours";
@import "mixins";

.list-container {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  width: 100%;

  .list-item {
    @include display-flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: -1px;
    padding: 0.75rem 1rem;
    border: 1px solid $list-border;
    background-color: $list-item-background;
    color: $list-item-text;
    white-space: nowrap;
    text-overflow: ellipsis;

    .list-item-icon {
      font-size: 1rem;
    }

    &.list-item-link {
      cursor: pointer;
      padding-right: 2rem;

      &:hover {
        border: 1px solid $list-border;
        color: $list-item-text-hover;
        background-color: $list-item-background-hover;
      }
    }

    .list-item-arrow {
      position: absolute;
      right: 1rem;
    }
  }

  .loading-indicator {
    font-size: 1.75rem;
  }
}
