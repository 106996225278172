@import "css/src/common";

.ShopItemCard {
  @include display-flex;
  flex-direction: column;
}

.ShopItemCardImage {
  margin-bottom: $spacing-m;

  img {
    @include transition(transform, 100ms, ease-in-out);
  }

  &:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.ShopItemCardName {
  flex-grow: 1;
  margin-bottom: $spacing-m;
}

.ShopItemCardPrice {
  margin-bottom: $spacing-m;
}
