@import "css/src/common";

// Primary and secondary buttons are themed through branding but this is the defaults.
$primary-default-background-color: $color-primary;
$primary-default-text-color: #ffffff;

$secondary-default-background-color: #f5f5f5;
$secondary-default-text-color: $font-color;

$button-success-background-color: #4ed963;
$button-success-text-color: #ffffff;

$button-danger-background-color: #e41e13;
$button-danger-text-color: #ffffff;

$button-warning-background-color: #f57c00;
$button-warning-text-color: #ffffff;

$button-disabled-background-color: #a6a6a6;
$button-disabled-text-color: #ffffff;

$button-white-background-color: #f5f5f5;
$button-white-text-color: $font-color;

$button-padding: 0.825em;
$font-weight: 500;
$border-radius: 4px;
$button-min-height: 2.5rem;
$button-min-width: 4.5rem;

// Button font sizes
$font-size-small: 0.875rem;
$font-size-medium: 1rem;
$font-size-large: 1.1rem;

.button[type="submit"],
.button[type="button"] {
  display: inline-block;
  position: relative;
  overflow: hidden;
  font-family: $default-font-family;
  font-size: $font-size-medium;
  font-weight: $font-weight;
  padding: 0 $button-padding;
  min-height: $button-min-height;
  border-radius: $border-radius;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;

  @media only screen and (min-width: 768px) {
    &:after {
      display: block;
      position: absolute;
      opacity: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.2);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:hover:after {
      @include transition(opacity, 100ms, ease-in-out);
      opacity: 1;
    }

    &.secondary:after {
      background: none;
      transform-origin: center;
      transform: scale3d(0.35, 0.35, 0.35);
    }
  }

  &:disabled {
    background-color: $button-disabled-background-color !important;
    color: $button-disabled-text-color !important;
    pointer-events: none !important;
  }

  &.primary {
    background-color: $primary-default-background-color;
    color: $primary-default-text-color;
  }

  &.secondary {
    background-color: transparent;
    color: $secondary-default-text-color;
    border: none;

    &:hover,
    &.active {
      color: $primary-default-background-color;
    }

    @media only screen and (min-width: 768px) {
      &:hover:after,
      &.active:after {
        @include transition(transform, 120ms, ease);
        background-color: $primary-default-background-color;
        opacity: 0.1;
        transform: scale3d(1, 1, 1);
      }
    }
  }

  &.success {
    background-color: $button-success-background-color;
    color: $button-success-text-color;
  }

  &.danger {
    background-color: $button-danger-background-color;
    color: $button-danger-text-color;
  }

  &.warning {
    background-color: $button-warning-background-color;
    color: $button-warning-text-color;
  }

  &.white {
    background-color: $button-white-background-color;
    color: $button-white-text-color;
  }

  &.small {
    font-size: $font-size-small;
  }

  &.large {
    min-height: 3.5rem;
    padding: 0.875rem 2rem;

    &.storageButton {
      font-size: $font-size-medium;
      min-height: 4rem;
    }
  }
}

.buttonGroup {
  @include display-flex;

  & > .button,
  & > a {
    display: inline-block;
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &.buttonGroupAlignRight {
    justify-content: right;
  }
}

.iconContainer {
  display: inline-block;

  .icon {
    position: relative;
    height: 1em;
    width: 1em;
    top: 2px;
    color: inherit;
  }
}

.labelWrapper {
  @include display-flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin: 0;
  }

  .icon + .label {
    margin-left: 0.5rem;
  }
}

.iconContainer + .label {
  margin-left: $button-padding/2;
}
