@import "css/src/variables";

.atlaskit-portal-container {
  position: relative;
  overflow: hidden;
  font-family: $header-font-family;

  // The alert container
  .css-1s9se2y {
  }

  // The flag alert containers (success, warning, info, error)
  .css-cze0hy,
  .css-yo5rp2,
  .css-m55q5t,
  .css-nxidjh {
    //padding: 1rem;
  }

  // The flag title
  .css-1sv38gu {
    //font-size: 1rem;
  }

  // The flag description
  .css-1q5php6 {
    //font-size: 0.75rem;
    //margin-top: 0.25rem;
  }
}
