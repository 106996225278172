@import "colours";
@import "mixins";

.icon {
  display: inline;
}

.icon-orange {
  color: $icon-orange;
}
svg.icon-orange {
  fill: $icon-orange;
}

.icon.icon-tip {
  font-size: 1rem;
  width: auto;
  height: auto;
}

.table-icon {
  margin: 0 auto;
  display: block;
}

i.table-icon {
  font-size: 1.5rem;
  width: auto;
  height: auto;
  text-align: center;
  -webkit-transition: color 100ms;
  -moz-transition: color 100ms;
  -ms-transition: color 100ms;
  -o-transition: color 100ms;
  transition: color 100ms;
}

// Icon spinning animation
.ion-spin {
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  width: auto;
  height: auto;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.ion-load-*.ion-spin {
  -webkit-animation-timing-function: steps(8, start);
  -moz-animation-timing-function: steps(8, start);
  animation-timing-function: steps(8, start);
}

.ion-bounce {
  -webkit-animation: bounce 3s infinite linear;
  -moz-animation: bounce 3s infinite linear;
  -o-animation: bounce 3s infinite linear;
  animation: bounce 3s infinite linear;
  transform-origin: center bottom;
}

@keyframes bounce {
  from,
  10%,
  23%,
  40%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  18%,
  20% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -12px, 0);
  }
  35% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  45% {
    transform: translate3d(0, -4px, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
}

.icon-size-small {
  font-size: 1rem;
}
.icon-size-medium {
  font-size: 1.5rem;
}
.icon-size-large {
  font-size: 2rem;
}
