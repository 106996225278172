@import "css/src/variables";
@import "css/src/colours";

.methods {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.scopeInput {
  margin-bottom: $minor-padding;
}
