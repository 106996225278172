$spacing: 20px;

.sectionEditPanel {
  padding: 1rem;
}

.inputGroups {
  margin-bottom: 0;
}

.contentInputGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: unset;

  label {
    flex: 0 0 10rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;
    padding-right: 0.5rem;
  }

  .contentInput {
    height: 3rem;
    width: 26rem;
  }

  &.groupHeader label {
    flex: 1 1 100%;
  }
}
