@import "css/src/common";

%QuantityInput-button {
  cursor: pointer;
}
%QuantityInput-icon {
  width: 0.75rem;
  height: 0.75rem;
}

.QuantityInput {
  @include display-flex;
  @include no-select;
  flex-direction: row;

  & > div {
    display: inline-block;
    border: 1px solid $input-border-color;
    padding: $input-padding;

    &:not(:first-child) {
      border-left: none;
    }
  }
}

.QuantityInputValue {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

.QuantityInputValueInput {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border: none;
  outline: none;
  width: 2rem;
  text-align: center;
}

.QuantityInputMinus,
.QuantityInputPlus {
  @extend %QuantityInput-button;
}

.QuantityInputMinusIcon,
.QuantityInputPlusIcon {
  @extend %QuantityInput-icon;
}
