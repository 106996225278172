@import "css/src/common";

.KanbanColumn {
  margin-right: $spacing-s;

  &:last-child {
    margin-right: 0;
  }
}

.Title {
  display: block;
  margin-bottom: $spacing-m;
  font-weight: bold;
  color: $font-color-light;
}

.KanbanColumnItems {
  padding: $spacing-xs;
  height: 100%;
  background-color: $page-secondary-background;
  border-radius: $border-radius;
  transition: background-color 150ms ease-out;
  transition-delay: 50ms;
}

.IsDraggingOver {
  background-color: $kanban-column-hover-background-color;
}
