@import "../mixins";

.locations-table {
  flex: auto;
  .table-header-row,
  .table-row {
    .table-cell:nth-child(1) {
      @include width(25%);
    }
    .table-cell:nth-child(2) {
      @include width(25%);
    }
    .table-cell:nth-child(3) {
      @include width(25%);
    }
    .table-cell:nth-child(4) {
      @include width(15%);
    }
    .table-cell:nth-child(5) {
      @include width(10%);
      text-align: right;
    }
  }
}
