@import "../colours";
@import "../mixins";

.create-checkout-modal {
  .modal-body {
    padding-bottom: 0;
    padding-top: 0;
  }

  .checkout-form,
  .checkout-authenticate-form {
    min-height: 12rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
  }
  .checkout-authenticate-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    input {
      margin-bottom: 0;
    }
    .input-group {
      margin-bottom: 0;
    }
  }
  .checkout-form {
    padding-top: 1rem;
  }
  .checkout-identity {
    margin-bottom: 1rem;
  }
  .checkout-items {
    margin-bottom: 1rem;

    .checkout-item {
    }
  }
}

.toolbox-dashboard {
  margin-top: -3rem;
  padding-top: 3rem;

  .grid,
  .grid-fluid {
    margin-bottom: 0;
  }
}

.view-tools-modal {
  .animated-component {
    right: 0;
    top: 0;
    position: absolute;
    width: 300px;
    height: 100vh;
    overflow-y: auto;

    &.visible {
      -webkit-animation-name: slideInRight;
      animation-name: slideInRight;
    }

    &.invisible {
      -webkit-animation-name: slideOutRight;
      animation-name: slideOutRight;
    }
  }

  .card {
    min-height: 100%;
  }
}

.tools-summary-table {
  .table-header-row,
  .table-row {
    .table-cell:nth-child(1) {
      @include width(80%);
    }
    .table-cell:nth-child(2) {
      @include width(20%);
    }
  }
}

.checkouts-table {
  .table-header-row,
  .table-row {
    .table-cell:nth-child(1) {
      @include width(25%);
    }
    .table-cell:nth-child(2) {
      @include width(25%);
    }
    .table-cell:nth-child(3) {
      @include width(25%);
    }
    .table-cell:nth-child(4) {
      @include width(25%);
    }
  }
}

.storage-idle-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-repeat: repeat;

  ul {
    display: flex;
    list-style: none;
    justify-content: center;
    padding-left: 0;

    li {
      padding: 1rem;
    }
  }

  .toolbox-actions {
    display: flex;
    position: absolute;
    justify-content: center;
  }

  .toolbox-actions {
    bottom: 0;
    width: 100%;
  }
}

.storage-idle-logo-wrapper {
  position: relative;
  z-index: -1;

  .storage-idle-logo {
    height: 136px;
    width: 280px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
}

@media only screen and (max-width: 475px) {
  .storage-idle-logo-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    .storage-idle-logo {
      position: relative;
    }
  }
}

// Checkouts empty state
.empty-state {
  @include display-flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100%;

  .state-icon {
    height: 175px;
    margin-bottom: 2rem;
  }
  h2 {
    margin-bottom: 0.5rem;
  }
}

// Launch pad colours
.launch-app.app-inventory:before {
  background-color: #0d345c;
}

.launch-app.app-checkouts:before {
  background-color: #ad342a;
}

.launch-app.app-alerts:before {
  background-color: #48c0b7;
}
