@import "css/src/variables";
@import "css/src/mixins";
@import "css/src/colours";

$platform-navbar-background-color: #ffffff;
$control-gap: 1rem;
$menu-elevation-1: -1px 2px 6px $card-box-shadow-color;

.navigationBar,
.controlsContainer,
.navContainer {
  @include display-flex;
}

.container {
  position: relative;
  width: 100vw;
  padding-bottom: $platform-navbar-height;
}

.navContainer {
  @include vertical-flex-align;
}

.navigationBar {
  position: fixed;
  width: 100%;
  @include box-shadow(0px, 0px, 4px, rgba(0, 0, 0, 0.16));
  z-index: $platform-navbar-z-index;
  flex-direction: row;
  justify-content: space-between;
  height: $platform-navbar-height;
  padding-left: $major-padding;
  padding-right: $major-padding;
  background-color: $platform-navbar-background-color;
}

.controlsContainer {
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.companyLogoAnchor {
  display: block;
}

.companyLogo {
  display: inline-block;
  max-width: 10rem;
  max-height: 3rem;
  width: 100%;
}

.profileContainer {
  @include display-flex;
  position: relative;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  &:hover,
  &.active {
    @include transition(background-color, 100ms);
    background-color: lighten($color-primary, 32%);
  }

  .profileAvatar {
    color: white;
    cursor: pointer;
  }

  .profileDropdown {
    position: absolute;
    min-width: 185px;
    top: 100%;
    margin-top: 4px;
    right: 0;
    background-color: $card-background-color;
    box-shadow: $menu-elevation-1;
    border-radius: $card-border-radius;
    padding-bottom: $minor-padding/2;

    h5 {
      padding: $minor-padding $minor-padding $minor-padding/2;
      white-space: nowrap;
      margin: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        white-space: nowrap;
        margin-bottom: 1rem;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: $font-color;
    }
  }
}

.appsControl {
  @include display-flex;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  width: 40px;
  color: $font-color;
  border-radius: 50%;
  cursor: pointer;
  margin-right: $control-gap;

  &:hover,
  &.active {
    @include transition(background-color, 100ms);
    background-color: lighten($color-primary, 32%);
    color: $color-primary;
  }

  .appsIcon {
    top: 0;
  }
}

.platformSearch {
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 18rem;
  margin-right: $control-gap;
}
