@import "css/src/common";

.ShopItemDocuments {
  margin-top: $padding-l;
}

.ShopItemDocuments_preview {
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
}
