@import "css/src/common";

.metadata {
  padding: 1rem;
  border-radius: $border-radius;
  border: 1px solid $input-border-color;
  min-width: 25rem;
  width: 50%;
  grid-row-gap: 1rem;
  grid-template-columns: 8rem auto;
}
