@import "css/src/common";

.ProjectKanbanItemProgress {
  flex-grow: 1;
}

.footer {
  @include display-flex;
  justify-content: space-between;
}

.input {
  margin-bottom: $spacing-xs;
}

.percentage {
  font-weight: 500;
}
