@import "css/src/variables";

.ShopItemPrimaryImage {
  width: 100%;

  &,
  & .image {
    border-radius: $border-radius * 4;
  }
}
