// Generic
$default-padding: 1.875rem;
$major-padding: 1.875rem;
$minor-padding: 1.25rem;
$padding-xl: 2rem;
$padding-l: $major-padding;
$padding-m: $minor-padding;
$padding-s: 1rem;
$padding-xs: 0.5rem;
$border-radius: 4px;

$spacing-xs: $padding-xs;
$spacing-s: $padding-s;
$spacing-m: $padding-m;
$spacing-l: $padding-l;
$spacing-xl: $padding-xl;

// Page font size
$root-font-size: 14px;
$default-font-family:
  "Inter",
  -apple-system,
  BlinkMacSystemFont,
  system-ui,
  sans-serif;
$header-font-family:
  "Inter",
  -apple-system,
  BlinkMacSystemFont,
  system-ui,
  sans-serif;
$header-1-font-size: 2.25rem;
$header-2-font-size: 1.75rem;
$header-3-font-size: 1.25rem;
$header-4-font-size: 1.125rem;
$font-size-s: 0.875rem;
$font-size-m: 1rem;
$font-size-l: 1.125rem;

// Page paddings
$manager-layout-offset: 1.25rem;
$sentry-layout-offset: $minor-padding;

// Button
$button-min-width: 5rem;
$button-group-item-spacing: 8px;

// Login form
$login-form-width: 320px;

// Signature explorer
$signature-explorer-height: 600px;

// Navbar
$navbar-padding: 0.625em 0.78125vw;
$platform-navbar-height: 4rem;
$platform-navbar-z-index: 9;

// Statistics container padding
$statistics-padding: 0.625em 0.78125vw 1.25em 0.78125vw;

// Sidebar
$sidebar-width: 19rem;
$sidebar-main-width-large: 19rem;
$sidebar-main-width-small: 7.5rem;
$page-gutter: 32px;

// Sidebar width
$sidebar-z-index: 0;
$sidebar-width: 18rem;
$page-gutter: 32px;

// Buttons
$button-border-radius: 4px;
$button-padding: 1rem;
$button-line-height: 1;
$button-min-width: 64px;

// Tabs
$tab-line-height: 2rem;

// Responsive breakpoints
$break-huge: 1440px;
$break-xl-large: 1224px;
$break-large: 1024px;
$break-medium: 768px;
$break-small: 568px;
$break-xs: 320px;

// Cards
$card-border-radius: 4px;
$card-content-padding: 1.5rem;
$card-title-line-height: 2rem;
$card-title-margin-bottom: 1rem;
$card-max-width: 600px;
$stage-card-padding: 3rem;

// Modal
$modal-wrapper-padding: 1.5rem;

// Input
$input-group-margin-bottom: 20px;
$input-groups-margin-bottom: $default-padding;
$input-font-size: 1rem;
$input-padding: 0.875rem;
$input-border-radius: 4px;

// Box shadows for cards etc
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow-md:
  0 0 #0000,
  0 0 #0000,
  0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg:
  0 0 #0000,
  0 0 #0000,
  0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 2px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-xl:
  0 0 #0000,
  0 0 #0000,
  0 20px 25px -5px rgba(0, 0, 0, 0.1),
  0 10px 10px -5px rgba(0, 0, 0, 0.04);
$shadow-inner:
  0 0 #0000,
  0 0 #0000,
  inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
