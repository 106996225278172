$primary-background-color: #b5d0ff;
$primary-text-color: #488aff;

$pill-success-background-color: #cbf7e0;
$pill-success-text-color: #1ed176;

$pill-danger-background-color: #ffd2d0;
$pill-danger-text-color: #e41e13;

$pill-warning-background-color: #fdefe5;
$pill-warning-text-color: #feb47b;

$pill-grey-background-color: rgb(243, 244, 246);
$pill-grey-text-color: rgb(51, 65, 85);

.pill {
  display: inline-block;
  font-size: 0.875em;
  border-radius: 4px;
  padding: 0.35em 0.625em;
  text-align: center;
  white-space: nowrap;

  &.primary {
    background-color: $primary-background-color;
    color: $primary-text-color;
  }

  &.success {
    background-color: $pill-success-background-color;
    color: $pill-success-text-color;
  }

  &.danger {
    background-color: $pill-danger-background-color;
    color: $pill-danger-text-color;
  }

  &.warning {
    background-color: $pill-warning-background-color;
    color: $pill-warning-text-color;
  }

  &.grey {
    background-color: $pill-grey-background-color;
    color: $pill-grey-text-color;
  }

  &.small {
    font-size: 0.75rem;
    padding: 0.25em 0.5rem;
  }

  &.fill {
    width: 100%;
  }
}
